import GeneralSection from "@ui/ComponentUtils/GeneralSection";
import Header from "@ui/ComponentUtils/Header";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import enums from "helpers/enums";
import { globalUserControl, search } from "@ui/ComponentUtils/blueprintAPIs";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import AirplanemodeInactiveIcon from "@mui/icons-material/AirplanemodeInactive";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import { fetchLocationName, isAdmin } from "@ui/Utils/helper";
import { updateRecord } from "../../api";
import commonEnums from "@ui/Utils/commonEnums";
import Emitter from "@ui/Utils/CustomEventEmitter";

const earthErrorMessage = "Something went wrong!";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DetailHeader = (props) => {
  const { isEditable, onSubmit, setIsEditable } = props;
  const formMethods = useFormContext();
  const values = formMethods.watch();

  const params = useParams();
  const navigate = useNavigate();
  const model = enums.models.users;
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const handleEarth = async () => {
    try {
      setCountries(
        ((await search(enums.models.iam.countries)) || []).map(
          (country) => country["name"]
        )
      );
    } catch (err) {
      CustomEventEmitter.emit("alert_error", earthErrorMessage);
    }
  };

  useEffect(() => {
    handleEarth();
  }, []);

  useEffect(() => {
    if (values.companyDetails?.address?.country) {
      (async () => {
        try {
          setStates(
            (
              (await search(enums.models.iam.states, {
                "country.name": values.companyDetails?.address?.country,
              })) || []
            ).map((state) => state["name"])
          );
        } catch (err) {
          CustomEventEmitter.emit("alert_error", earthErrorMessage);
        }
      })();
    }
  }, [values.companyDetails?.address?.country]);

  useEffect(() => {
    if (values.companyDetails?.address?.state) {
      (async () => {
        try {
          setCities(
            (
              (await search(enums.models.iam.cities, {
                "country.name": values.companyDetails?.address?.country,
                "state.name": values.companyDetails?.address?.state,
              })) || []
            ).map((city) => city["name"])
          );
        } catch (err) {
          CustomEventEmitter.emit("alert_error", earthErrorMessage);
        }
      })();
    }
  }, [values.companyDetails?.address?.state]);

  const handleCancel = () => {
    if (window.confirm("Are you sure to cancel? Changes will be discarded.")) {
      if (params?.code === "create") {
        navigate("/" + model);
      } else {
        formMethods.reset();
        setIsEditable(false);
      }
    }
  };

  const entityFields = [
    {
      name: "firstName",
      label: "First Name",
      fieldType: "textField",
    },
    {
      name: "lastName",
      label: "Last Name",
      fieldType: "textField",
    },
    {
      name: "companyDetails.name",
      label: "Company Name",
      fieldType: "textField",
    },
    {
      name: "phone",
      label: "Phone",
      fieldType: "textField",
      dataType: enums.dataTypes.number,
    },
    {
      name: "password",
      label: "Password",
      fieldType: "textField",
      hash: true,
    },
    {
      name: "email",
      label: "Email",
      fieldType: "textField",
    },

    {
      name: "companyDetails.address.country",
      label: "Country",
      fieldType: enums.fieldTypes.dropdown,
      options: countries,
      render: "companyDetails.address.country",
      autoComplete: false,
    },
    {
      name: "companyDetails.address.state",
      label: "State",
      fieldType: enums.fieldTypes.dropdown,
      options: states,
      render: "companyDetails.address.state",
      autoComplete: false,
    },
    {
      name: "companyDetails.address.city",
      label: "City",
      fieldType: enums.fieldTypes.dropdown,
      options: cities,
      render: "companyDetails.address.city",
      autoComplete: false,
    },

    {
      name: "companyDetails.address.street",
      label: "Street",
      fieldType: "textField",
    },
    {
      name: "companyDetails.address.pincode",
      label: "Pincode",
      fieldType: "textField",
    },
    {
      name: "manager",
      label: "Manager",
      fieldType: enums.fieldTypes.asyncDropdown,
      groupBy: "role",
      apiUrl: enums.models.iam.users + "/search",
      getOptionLabel: (option) =>
        option?.firstName
          ? option?.firstName + " " + (option?.lastName || "")
          : "",
      getOptionSelected: (option, value) =>
        option?.firstName + " " + option?.lastName ===
        value?.firstName + " " + value?.lastName,

      render: () =>
        (values.manager?.firstName || "") +
        " " +
        (values.manager?.lastName || ""),
    },

    {
      name: "lastKnownLocation",
      label: "Last Known Location",
    },
    {
      name: "businessType",
      label: "Business",
      fieldType: "dropdown",
      options: Object.values(commonEnums.customerBusinessType),
    },
    {
      name: "companyDetails.kyc.gst",
      label: "GST",
      fieldType: "textField",
    },
  ];

  const links = [
    {
      name: "Customers",
      url: "#/" + model,
      isDisplayLink: true,
    },
    {
      name: (values.firstName || "") + " " + (values.lastName || ""),
      url: "",
      isDisplayLink: false,
    },
  ];

  const actions = [
    {
      hidden: isEditable || !isAdmin,
      label: "Signout All Apps",
      iconName: "signout",
      tooltip: "Signout " + values.firstName,
      onClick: async () => {
        try {
          const res = await globalUserControl(enums.models.users, {
            users: [
              {
                _id: values._id,
                firstName: values.firstName,
                lastName: values.lastName,
              },
            ],
          });

          Emitter.emit(
            "alert_success",
            `Logged out ${res.nModified || 0} user successfully...`
          );
        } catch (_) {
          Emitter.emit("alert_error", "Something went wrong!");
        }
      },
      color: "primary",
    },
    {
      label: values.status ? "Deactivate User" : "Activate User",
      iconName: () =>
        values.status ? (
          <AirplanemodeInactiveIcon />
        ) : (
          <AirplanemodeActiveIcon />
        ),
      hidden: isEditable || !isAdmin,
      onClick: async () => {
        try {
          if (
            window.confirm(
              `Are you sure to ${
                values.status ? "Deactivate" : "Activate"
              } this user?`
            )
          ) {
            const res = await updateRecord(model, {
              code: values.code,
              status: !values.status,
            });

            await globalUserControl(enums.models.users, {
              users: [
                {
                  _id: values._id,
                  firstName: values.firstName,
                  lastName: values.lastName,
                },
              ],
            });

            CustomEventEmitter.emit(
              "alert_success",
              (res.status ? "Activated" : "Deactivated") + " User Successfully"
            );
            if (res?.lastKnownLocation?.coords) {
              const location = await fetchLocationName(
                res?.lastKnownLocation?.coords?.latitude,
                res?.lastKnownLocation?.coords?.longitude
              );

              res.lastKnownLocation = location;
            }

            formMethods.reset(res);
          }
        } catch (err) {
          console.log(err);

          CustomEventEmitter.emit(
            "alert_success",
            err?.response?.data?.message || "Something went wrong"
          );
        }
      },
    },
    {
      label: "Cancel",
      iconName: "cancel",
      hidden: !isEditable,
      onClick: handleCancel,
    },
    {
      label: "Save",
      iconName: "save",
      hidden: !isEditable,
      type: "submit",
      onClick: formMethods.handleSubmit(onSubmit),
    },
    {
      label: "Edit",
      iconName: "edit",
      hidden: isEditable || !isAdmin,
      onClick: () => {
        setIsEditable(true);
      },
    },
  ];

  return (
    <>
      <Header links={links} pageTitle={"Customers"} actions={actions} />

      <GeneralSection
        // actions={sectionActions}
        fields={entityFields}
        title="Information"
        isEditable={isEditable}
        values={values}
      />
    </>
  );
};

export default DetailHeader;
